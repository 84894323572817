import React from 'react';

// Components
import { Wrapper, Inner, Left, Right } from './files';

const ContactFind = ({ contentSections }) => (
  <Wrapper>
    <Inner>
      <Left />
      <Right contentSections={contentSections} />
    </Inner>
  </Wrapper>
);

export default ContactFind;
