import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 28px 124px 15px 0;
  @media only screen and (max-width: ${props =>
      props.theme.responsive.medium}) {
    padding: 0 70px;
  }
  h3 {
    margin-top: 80px;
    margin-bottom: 0.75em;
    font-size: 1em;
    text-transform: uppercase;
    &:first-child {
      margin-top: 0;
    }
  }
  p {
    text-align: justify;
  }
  a {
    cursor: pointer;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    height: 50px;
    margin: 0;
    padding: 0 20px;
    background: ${props => props.theme.colors.primary};
    font: 700 16px/50px ${props => props.theme.fonts.body};
    text-transform: uppercase;
    text-align-last: auto;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    color: ${props => props.theme.colors.text.light};
    transition: color 0.2s, background-color 0.2s, border-color 0.2s;
    overflow: hidden;
    text-overflow: ellipsis;
    outline: none;
    &:hover {
      background: ${props => props.theme.colors.secondary};
    }
  }
`;

const Right = ({ contentSection }) => (
  <Wrapper>
    <h3>{contentSection.title}</h3>
    <span
      dangerouslySetInnerHTML={{
        __html: contentSection.text.childMarkdownRemark.html
      }}
    />
  </Wrapper>
);

export default Right;
