import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  padding-top: 70px;
  padding-bottom: 70px;
  background: ${props => props.theme.colors.background.lightAlt};
  &::after {
    clear: both;
    content: '';
    display: table;
  }
  * {
    box-sizing: border-box;
  }
`;

export default Wrapper;
