import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-right: 0 !important;
  padding-left: 0 !important;
  box-sizing: content-box;
  &::after {
    clear: both;
    content: '';
    display: table;
  }
  * {
    box-sizing: border-box;
  }
  & > div {
    position: relative;
    @media screen and (min-width: ${props => props.theme.responsive.small}) {
      height: 80vh;
    }
    @media screen and (max-width: ${props => props.theme.responsive.small}) {
      height: 60vh;
    }
    & > div {
      position: relative;
      overflow: hidden;
      border-top: 1px solid ${props => props.theme.colors.misc.border};
      border-bottom: 1px solid ${props => props.theme.colors.misc.border};
    }
  }
`;

export default Wrapper;
