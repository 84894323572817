import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  padding-top: 70px;
  padding-bottom: 100px;
  border-bottom: 1px solid ${props => props.theme.colors.misc.border};
  @media only screen and (max-width: ${props =>
      props.theme.responsive.medium}) {
    padding-bottom: 0;
  }
  &::after {
    clear: both;
    content: '';
    display: table;
  }
  * {
    box-sizing: border-box;
  }
`;

export default Wrapper;
