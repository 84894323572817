import React from 'react';
import { graphql } from 'gatsby';

// Components
import SeoDetails from '../components/common/seoDetails';
import HeroImage from '../components/common/sections/heroImage';
import ContactDetails from '../components/common/sections/contact/details';
import ContactFind from '../components/common/sections/contact/find';
import ContactArrival from '../components/common/sections/contact/arrival';
import ContactHours from '../components/common/sections/contact/hours';
import Map from '../components/common/sections/map';

const ContactPage = ({ data }) => {
  const { seoContent, contentSections } = data.contentfulPages;
  const { name, coordinates } = data.contentfulCompanyInformation;

  return (
    <>
      <SeoDetails seoContent={seoContent} />
      <HeroImage seoContent={seoContent} />
      <ContactDetails contentSection={contentSections[0]} />
      <ContactFind contentSections={contentSections} />
      <ContactArrival contentSection={contentSections[3]} />
      <Map text={name} mapCoordinates={coordinates} zoom={13} />
      <ContactHours contentSection={contentSections[4]} />
    </>
  );
};

export const query = graphql`
  query {
    contentfulPages(id: { eq: "af63a0a5-3c05-5a04-818e-8b9cfbc5d959" }) {
      id
      title
      seoContent {
        pageTitle
        menuTitle
        slug
        description {
          description
        }
        featuredImage {
          title
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      contentSections {
        title
        subtitle
        text {
          childMarkdownRemark {
            html
          }
        }
        image {
          title
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
    contentfulCompanyInformation(
      id: { eq: "59e3746d-e072-58a3-a341-04607ebd3e33" }
    ) {
      id
      name
      coordinates {
        lon
        lat
      }
    }
  }
`;

export default ContactPage;
