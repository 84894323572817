import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

// Context
import ContextConsumer from '../../../../contextProvider';

// Icons
import {
  Facebook,
  Twitter,
  TripAdvisor
} from '../../../../../../assets/images/icons/icons';

const Wrapper = styled.div`
  padding: 20px 124px;
  @media only screen and (max-width: ${props =>
      props.theme.responsive.medium}) {
    padding: 0 70px 70px;
  }
  h2 {
    color: ${props => props.theme.colors.text.dark};
    font-size: 25px;
    line-height: 35px;
    font-weight: 700;
    text-transform: uppercase;
    @media only screen and (max-width: ${props =>
        props.theme.responsive.small}) {
      padding-bottom: 15px;
      font-size: 22px;
      line-height: 25px;
    }
  }
  h4 {
    color: ${props => props.theme.colors.text.dark};
    font-size: 1em;
    font-weight: 700;
    text-transform: uppercase;
    margin: 30px 0 10px;
  }
`;

const SocialList = styled.ul`
  font-size: 0;
  width: 100%;
  display: inline-block;
  li {
    display: inline-block;
    margin-left: 5px;
    &:first-child {
      margin-left: 0;
    }
    &:after {
      clear: both;
      content: '';
      display: table;
    }
    a {
      position: relative;
      display: block;
      width: 41px;
      height: 41px;
      overflow: hidden;
      text-decoration: none;
      @media only screen and (max-width: ${props =>
          props.theme.responsive.small}) {
        display: inline-block;
      }
      svg {
        border: 1px solid ${props => props.theme.colors.text.normal};
        border-radius: 50%;
        transition: all 300ms ease-in-out;
        path {
          transition: all 300ms ease-in-out;
          fill: ${props => props.theme.colors.text.normal};
        }
      }
      &:hover {
        svg {
          border: 1px solid ${props => props.theme.colors.primary};
          path {
            fill: ${props => props.theme.colors.primary};
          }
        }
      }
    }
  }
`;

const Left = () => (
  <ContextConsumer>
    {({ countryCode }) => (
      <StaticQuery
        query={graphql`
          query {
            contentfulCompanyInformation(
              id: { eq: "59e3746d-e072-58a3-a341-04607ebd3e33" }
            ) {
              name
              address
              phone
              email
              facebook
              twitter
              tripAdvisor
            }
          }
        `}
        render={data => {
          const {
            name,
            address,
            phone,
            email,
            facebook,
            twitter,
            tripAdvisor
          } = data.contentfulCompanyInformation;
          const cleaned = ('' + phone).replace(/\D/g, '');

          const LinkPhoneNumber = '+61' + cleaned.replace(/\b0+/g, '');

          function LocalPhoneNumber() {
            const match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
            if (match) {
              return '(' + match[1] + ') ' + match[2] + ' ' + match[3];
            }
            return null;
          }

          function IntPhoneNumber() {
            const match = cleaned.match(/^(\d{1})(\d{1})(\d{4})(\d{4})$/);
            if (match) {
              return '+61 (' + match[2] + ') ' + match[3] + ' ' + match[4];
            }
            return null;
          }
          return (
            <Wrapper>
              <h2>{name}</h2>
              <ul>
                <li>
                  <a
                    href={`tel:${LinkPhoneNumber}`}
                    title={`Call ${name} on ${LinkPhoneNumber}`}
                  >
                    Phone:{' '}
                    {countryCode !== 'AU' ? (
                      <IntPhoneNumber />
                    ) : (
                      <LocalPhoneNumber />
                    )}
                  </a>
                </li>
                <li>
                  <a
                    href={`mailto:${email}`}
                    title={email}
                    aria-label={`Email ${name}`}
                  >
                    Email: {email}
                  </a>
                </li>
                <br />
                <li>{address}</li>
              </ul>
              <h4>Follow Us</h4>
              <SocialList>
                <li>
                  <a href={facebook} target="_blank" rel="noopener noreferrer">
                    {Facebook}
                  </a>
                </li>
                <li>
                  <a href={twitter} target="_blank" rel="noopener noreferrer">
                    {Twitter}
                  </a>
                </li>
                <li>
                  <a
                    href={tripAdvisor}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {TripAdvisor}
                  </a>
                </li>
              </SocialList>
            </Wrapper>
          );
        }}
      />
    )}
  </ContextConsumer>
);

export default Left;
