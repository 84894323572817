import React from 'react';
import styled from 'styled-components';

// Config
import config from '../../../../../../../config/website';

const Wrapper = styled.div`
  padding: 20px 124px;
  @media only screen and (max-width: ${props =>
      props.theme.responsive.medium}) {
    padding: 0 70px;
  }
  h2 {
    color: ${props => props.theme.colors.text.dark};
    font-size: 25px;
    line-height: 35px;
    font-weight: 700;
    text-transform: uppercase;
    @media only screen and (max-width: ${props =>
        props.theme.responsive.small}) {
      padding-bottom: 15px;
      font-size: 22px;
      line-height: 25px;
    }
  }
`;

const Left = () => (
  <Wrapper>
    <h2>Finding {config.siteTitle}</h2>
  </Wrapper>
);

export default Left;
