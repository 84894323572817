import React from 'react';

// Components
import { Wrapper, GoogleMap } from './files';

const Map = ({ text, mapCoordinates, zoom }) => (
  <Wrapper>
    <div>
      <GoogleMap text={text} mapCoordinates={mapCoordinates} zoom={zoom} />
    </div>
  </Wrapper>
);

export default Map;
