import React from 'react';
import Img from 'gatsby-image';

// Components
import { Wrapper, Inner, Left } from './files';

const ContactDetails = ({ contentSection }) => (
  <Wrapper>
    <Inner>
      <Left />
      <Img
        fluid={contentSection.image.fluid}
        alt={contentSection.image.title}
      />
    </Inner>
  </Wrapper>
);

export default ContactDetails;
