import React from 'react';
import GoogleMapReact from 'google-map-react';

const MapPointer = ({ text }) => (
  <div className="pulse_holder">
    <span id={text} className="pulse_marker" />
  </div>
);

class GoogleMap extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    function createMapOptions() {
      return {
        gestureHandling: 'cooperative',
        styles: [
          {
            featureType: 'administrative',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#444444'
              }
            ]
          },
          {
            featureType: 'landscape',
            elementType: 'all',
            stylers: [
              {
                color: '#f2f2f2'
              }
            ]
          },
          {
            featureType: 'landscape.natural',
            elementType: 'all',
            stylers: [
              {
                visibility: 'on'
              },
              {
                color: '#e6e6e6'
              }
            ]
          },
          {
            featureType: 'poi',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'all',
            stylers: [
              {
                saturation: -100
              },
              {
                lightness: 45
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'geometry.fill',
            stylers: [
              {
                visibility: 'on'
              },
              {
                hue: '#ffb901'
              }
            ]
          },
          {
            featureType: 'road.highway',
            elementType: 'all',
            stylers: [
              {
                visibility: 'simplified'
              }
            ]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.fill',
            stylers: [
              {
                color: '#ffb901'
              },
              {
                saturation: '85'
              },
              {
                lightness: '31'
              },
              {
                gamma: '1.24'
              }
            ]
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text',
            stylers: [
              {
                visibility: 'simplified'
              }
            ]
          },
          {
            featureType: 'road.arterial',
            elementType: 'geometry.fill',
            stylers: [
              {
                hue: '#ffb901'
              },
              {
                saturation: '1'
              }
            ]
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'transit',
            elementType: 'all',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'transit.station',
            elementType: 'all',
            stylers: [
              {
                visibility: 'simplified'
              },
              {
                hue: '#ff0000'
              },
              {
                saturation: '-100'
              }
            ]
          },
          {
            featureType: 'transit.station.airport',
            elementType: 'all',
            stylers: [
              {
                visibility: 'on'
              }
            ]
          },
          {
            featureType: 'transit.station.bus',
            elementType: 'all',
            stylers: [
              {
                visibility: 'simplified'
              }
            ]
          },
          {
            featureType: 'transit.station.rail',
            elementType: 'all',
            stylers: [
              {
                visibility: 'simplified'
              },
              {
                hue: '#ff7e00'
              },
              {
                saturation: '-100'
              },
              {
                lightness: '19'
              }
            ]
          },
          {
            featureType: 'water',
            elementType: 'all',
            stylers: [
              {
                color: '#d7d7d7'
              },
              {
                visibility: 'on'
              }
            ]
          }
        ]
      };
    }

    const { text, mapCoordinates, zoom } = this.props;

    const center = {
      lat: mapCoordinates.lat,
      lng: mapCoordinates.lon
    };

    return (
      <GoogleMapReact
        options={createMapOptions}
        bootstrapURLKeys={{
          key: process.env.GATSBY_GOOGLE_MAPS_API_KEY
        }}
        defaultCenter={center}
        defaultZoom={zoom}
      >
        <MapPointer
          lat={mapCoordinates.lat}
          lng={mapCoordinates.lon}
          text={text}
        />
      </GoogleMapReact>
    );
  }
}

export default GoogleMap;
